import logoImg from '../../assets/logo.png';
import {Container, Content} from './styles';

export function Header() {
    return (
        <Container>
            <Content>
                <a href="/">
                    <img src={logoImg} alt="Food Checker"/>
                </a>
            </Content>
        </Container>
    )
}
