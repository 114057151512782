import { Container, Content } from './styles';

export function Footer() {
    return (
        <Container>
            <Content>
                <h2>Dúvidas?</h2>
                <div className="contact">
                    <div>
                        <div className="icon"><i className="fa fa-envelope"/></div>
                        <h3>Contato</h3>
                        <p>
                            <a href="https://app.foodchecker.com.br/support/tickets/new" target="_blank"
                               rel="noreferrer">clique aqui para enviar uma mensagem</a>
                            <br/><br/>
                            ou envie um e-mail direto para<br/>
                            <a href="mailto:contato@foodchecker.com.br" target="_blank"
                               rel="noreferrer">contato@foodchecker.com.br</a>
                            <br/>
                            <small>&ensp;</small>
                        </p>
                    </div>
                    <div>
                        <div className="icon"><i className="fa fa-whatsapp"/></div>
                        <h3>WhatsApp</h3>
                        <p>
                            <a href="https://api.whatsapp.com/send?phone=5511963722475&text=Ol%C3%A1!%20Estou%20assistindo%20v%C3%ADdeos%20do%20Food%20Checker%20e%20gostaria%20de%20suporte..."
                               target="_blank" rel="noreferrer">(11) 96372-2475</a>
                            <br/>
                            <small>De segunda à sexta das 9h às 17h.</small>
                        </p>
                    </div>
                </div>
                <p className="copyright">Copyright © 2023, Food Checker. Todos os direitos reservados.</p>
            </Content>
        </Container>
    )
}
