import styled from "styled-components";

export const Container = styled.header`
    
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .nav {
    background: var(--shape);
    border: 1px solid var(--border);
    width: 100%;
    padding: 2rem 4rem;
    border-radius: 4px;
    margin-top: -1rem;

    h2 {
      color: var(--success);
      text-align: center;
      margin-bottom: 1rem;

      @media (max-width: 767px) {
        text-align: left;
        font-size: 1.4rem;
      }
    }

      .tabs {
          text-align: center;
          margin-bottom: 2rem;
          border-bottom: 1px solid #cccccc;

          button {
              font-size: 1.2rem;
              font-weight: 400;
              color: #26b484;
              border-radius: 5px 5px 0 0;
              border: 1px solid #cccccc;
              padding: 10px;
              margin: 0 3px -1px;

              &.active {
                  background-color: #ffffff;
                  border-bottom-color: #ffffff;
              }
          }
      }

      @media (max-width: 767px) {
          padding: 2rem 1.2rem 2rem 3.8rem;

          .tabs {
              margin-left: -3.8rem;
              margin-right: -1.2rem;

              button {
                  margin: 0 -1px -1px;
                  padding: 10px 7px;
              }
          }
      }
  }

  .steps {
    display: flex;
    justify-content: center;
    
    ol, ul {
      
      li {
        line-height: 1.6;
        font-size: 1.2rem;
        font-weight: 300;
        padding-left: .2rem;
        margin-bottom: 1rem;
        list-style: decimal-leading-zero;
          max-width: 720px;

        @media (max-width: 767px) {
          padding-left: 0;
        }
        
        &::marker {
          font-weight: 600;
          font-family: monospace;
        }
        
        a {
          color: var(--color-text);
          border-bottom: 1px solid #b2b2b2;
          text-decoration: none;
        }
        
        &.active > {
          a {
            color: var(--warning);
          }
        }
        
        .steps-childrens {
          
          ol {
          
            li {
              list-style: disc;
              margin-top: 0.5rem;
              margin-left: 2rem;
              margin-bottom: 0;
              padding-left: 0;
        
                &.active > {
                  a {
                    color: var(--warning);
                  }
                }
            }
          }
        }
      }
    }
  }
`;
