import { Link } from 'react-router-dom';
import { GetPages, GetPath } from "../../utils/Pages";
import { Container, Content } from './styles';
import { useState } from "react";

export function Steps() {

    const pages = GetPages();
    const path = GetPath() || '/';

    const [activeTab, setActiveTab] = useState<'videos' | 'artigos'>(() => {
        const hasArticles = path.includes('artigo');
        return hasArticles ? 'artigos' : 'videos';
    });

    const handleTabClick = (tab: 'videos' | 'artigos') => {
        setActiveTab(tab);
    };

    return (
        <Container>
            <Content>
                <div className="nav">
                    <div className="tabs">
                        <button
                            onClick={ () => handleTabClick('videos') }
                            className={ activeTab === 'videos' ? 'active' : '' }
                        >
                            Conheça o Food Checker
                        </button>
                        <button
                            onClick={ () => handleTabClick('artigos') }
                            className={ activeTab === 'artigos' ? 'active' : '' }
                        >
                            Artigos
                        </button>
                    </div>


                    {activeTab === 'videos' && (
                        <div className="steps">
                            <ol>
                                { pages.map((item: any, index: number) =>
                                        item.path !== "artigo" && (
                                            <li key={ index } className={ path === item.path ? 'active' : '' }>
                                                <Link to={ `/${ item.path !== '/' ? item.path : '' }` }>
                                                    { item.title }
                                                </Link>
                                                { Object.keys(item.childrens).length > 0 &&
                                                    <div className="steps-childrens">
                                                        <ol>
                                                            { Object.keys(item.childrens).map((item2: any, index2: number) =>
                                                                <li key={ `${ index }-${ index2 }` }
                                                                    className={ path === `${ item.path }/${ item2 }` ? 'active' : '' }>
                                                                    <Link to={ `/${ item.path }/${ item2 }` }>
                                                                        { item.childrens[item2].title }
                                                                    </Link>
                                                                </li>
                                                            ) }
                                                        </ol>
                                                    </div>
                                                }
                                            </li>
                                        )
                                ) }
                            </ol>
                        </div>
                    )}

                    {activeTab === 'artigos' && (
                    <div className="steps">
                        <ul>
                            { pages
                                .filter((item: any) => item.path === 'artigo' && Object.keys(item.childrens).length > 0)
                                .map((item: any, index: number) =>
                                    Object.keys(item.childrens).map((childKey, index2) => (
                                        <li
                                            key={ `${ index }-${ index2 }` }
                                            className={ path === `${ item.path }/${ childKey }` ? 'active' : '' }
                                        >
                                            <Link to={ `/${ item.path }/${ childKey }` }>
                                                { item.childrens[childKey].title }
                                            </Link>
                                        </li>
                                    ))
                                ) }
                        </ul>
                    </div>
                    ) }
                </div>
            </Content>
        </Container>
    )
}
