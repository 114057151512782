import styled from "styled-components";

export const Container = styled.header`
  background-color: var(--success) !important;
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 1rem;
  }
`;
