import { Container, Content } from './styles';

export function CallToAction() {
    return (
        <Container>
            <Content>
                <h2>Agende uma Demonstração!</h2>
                <p>Entre em contato conosco e agende uma demostração sem compromisso para saber mais sobre o Food
                    Checker.<br/>Assim, você conhece todas as funcionalidades e tira suas dúvidas.</p>
                <div>
                    <a href="https://api.whatsapp.com/send?phone=5511963722475&text=Olá, quero agendar uma demonstração."
                       target="_blank" className="call" rel="noreferrer">Agendar Demonstração</a>
                </div>
                <a href="/" className="more">Assistir outros vídeos</a>
            </Content>
        </Container>
    )
}
