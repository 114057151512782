import { useState, useEffect } from 'react';
import { GetPage } from '../../utils/Pages';
import { Container, Content, ArticleWrapper } from './styles';

export function Article() {

    const page = GetPage();
    const [articleContent, setArticleContent] = useState('');

    console.log(page);

    useEffect(() => {
        if (page.html) {
            fetch(`/articles/${page.html}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Erro ao carregar o artigo");
                    }
                    return response.text();
                })
                .then(html => {
                    setArticleContent(html);
                })
                .catch(error => {
                    console.error("Erro ao carregar o artigo:", error);
                    setArticleContent("<p>Erro ao carregar o artigo. Por favor, tente novamente mais tarde.</p>");
                });
        }
    }, [page.html]);

    return (
        <Container>
            <Content>
                <h1>{page.title}</h1>
                <ArticleWrapper
                    dangerouslySetInnerHTML={{ __html: articleContent }}
                />
            </Content>
        </Container>
    );
}
