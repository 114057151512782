import { GetPage } from '../../utils/Pages';
import { Container, Content, VideoWrapper } from './styles';

export function Video() {

    const page: any = GetPage();

    return (
        <Container>
            <Content>
                <h1>{page.title}</h1>
                <VideoWrapper>
                    <iframe
                        width="853"
                        height="480"
                        src={`https://player.vimeo.com/video/${page.video}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; fullscreen; encrypted-media; gyroscope; picture-in-picture"
                        title={page.title}
                        allowFullScreen
                    />
                </VideoWrapper>
            </Content>
        </Container>
    )
}
