import styled from "styled-components";

export const Container = styled.header`
  background: var(--shape) !important;
  border-bottom: 1px solid var(--border);
`;

export const Content = styled.div`
  max-width: 853px;
  margin: 0 auto;
  padding: 1rem;
  
  h1 {
    text-align: center;
    margin: 1rem 0 1.5rem;

    @media (max-width: 767px) {
      font-size: 1.6rem;
      margin: 0 0 1rem;
    }
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
