import { CallToAction } from "./components/CallToAction";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { ScrollToTop } from './components/ScrollToTop';
import { Steps } from "./components/Steps";
import { Training } from "./components/Training";
import { Video } from "./components/Video";

import { GlobalStyle } from "./styles/global";
import { GetPath } from "./utils/Pages";
import { Article } from "./components/Article";

export function App() {

    const path: any = GetPath();

    if (path === 'analise-inicial') {

        return (
            <>
                <ScrollToTop/>
                <Header/>
                <Training/>
                <CallToAction/>
                <Footer/>
                <GlobalStyle/>
            </>
        );
    }

    return (
        <>
            <ScrollToTop/>
            <Header/>
            {path.includes('artigo') ? <Article /> : <Video />}
            <Steps/>
            <Footer/>
            <GlobalStyle/>
        </>
    );
}
