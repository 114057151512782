import styled from "styled-components";

export const Container = styled.header`
  background: var(--bg-black) !important;
  border-top: 1px solid var(--border);
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2rem 1rem;

  h2 {
    color: var(--text-light);
    text-align: center;
  }
  
  .contact {
    text-align: center;
    
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    
    .icon {
      padding-top: 2rem;
      color: var(--text-light);
      font-size: 3rem;
    }
    
    h3 {
      color: var(--text-light);
      margin-bottom: .5rem;
    }
    
    a {
      color: var(--text-light);
      text-decoration: none;
      border-bottom: 1px solid var(--border-secondary);
      
      &:hover {
        color: var(--warning);
      }
    }
  }
  
  .copyright {
    border-top: 1px solid var(--border-secondary);
    color: var(--text-light-secondary);
    display: block;
    font-size: .8rem;
    text-align: center;
    padding: 1rem 0 0;
    margin-top: 4rem;
  }
`;
