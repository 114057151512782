import { Container, Content, VideoWrapper } from './styles';

export function Training() {

    // const page: any = GetPage();
    const title: string = 'Iniciando a Consultoria com o Food Checker';
    const video: string = '779697868';

    return (
        <Container>
            <Content>
                <h1>{title}</h1>
                <VideoWrapper>
                    <iframe
                        width="853"
                        height="480"
                        src={`https://player.vimeo.com/video/${video}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; fullscreen; encrypted-media; gyroscope; picture-in-picture"
                        title={title}
                        allowFullScreen
                    />
                </VideoWrapper>
            </Content>
        </Container>
    )
}
