import styled from "styled-components";

export const Container = styled.header`
  background: var(--bg-gray);
  border-top: 1px solid var(--border);
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 3rem 1rem;
  text-align: center;

  h2 {
    color: var(--text-default);
  }
  
  p {
    color: var(--text-default);
    margin: 2rem auto;
  }
  
  a.call {
    background-color: #5f2d88;
    color: white;
    padding: 15px 25px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    display: inline-block;
    border-radius: 5px;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  
  a.more {
    color: #26b484;
    text-decoration: none;
  }
`;
